<template>
  <v-container>

    <!-- Contact card -->
    <v-card class="mx-auto mb-7" max-width="1200" elevation="4">
      <v-img
          class="white--text align-end blurredHeader"
          height="200px"
          src="@/assets/galpon-team.jpg"
      >
        <v-card-title class="pb-0">Necesita más ayuda o información?</v-card-title>
        <v-card-title class="text-h5 pt-0">Contáctenos</v-card-title>

      </v-img>

      <v-row class="px-4">

        <v-col sm="12" md="4" class="pb-0">

          <v-card class="mx-auto mt-6" >

            <v-card-title>
              <v-avatar tile>
                <v-img
                  src="@/assets/logoGalpon.png"
                />

              </v-avatar>
              <span class="ml-2">Datos de Contacto</span>
              </v-card-title>

            <v-list two-line>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-account
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{contact.name}}</v-list-item-title>
                  <v-list-item-subtitle>Jefe Área Artístico Cultural</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-phone
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
                  <v-list-item-title>{{contact.phone}}</v-list-item-title>
                </v-list-item-content>

              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item v-for="(mail, idx) in contact.email" :key="idx">
                <v-list-item-icon>
                  <v-icon v-if="idx == 0" color="indigo">
                    mdi-email
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-subtitle v-if="idx == 0">Correo electrónico</v-list-item-subtitle>
                  <v-list-item-title>{{mail}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-map-marker
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                  <v-list-item-title>{{contact.address}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>

        </v-col>

        <v-col sm="12" md="8" class="pt-8">
          <div class="mb-0">
            <iframe width="100%" height="450" id="gmap_canvas" src="https://maps.google.com/maps?q=Larrondo%201281,%20Coquimbo&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" class="roundedmap" />
          </div>
        </v-col>

      </v-row>

    </v-card>

    <!-- Social networks card -->
    <v-card class="mx-auto mb-7" max-width="1200" elevation="4">

      <v-card-title class="text-md-body-1 pb-0">Entérese de lo último en nuestras</v-card-title>
      <v-card-title class="text-h5 pt-0">Redes Sociales</v-card-title>

      <v-card-text class="px-7">

        <v-btn v-if="socialNetworks.facebook.isEnabled" :href="socialNetworks.facebook.url" color="#3b5998" dark class="mr-2 mt-2" small>
          <v-icon dark class="mr-2">mdi-facebook</v-icon>
          Facebook
        </v-btn>

        <v-btn v-if="socialNetworks.instagram.isEnabled" :href="socialNetworks.instagram.url" dark small class="instagramButton mr-2 mt-2">
          <v-icon dark class="mr-2">mdi-instagram</v-icon>
          Instagram
        </v-btn>

        <v-btn v-if="socialNetworks.youtube.isEnabled" :href="socialNetworks.youtube.url" color="#ff0000" dark small class="mt-2">
          <v-icon dark class="mr-2">mdi-youtube</v-icon>
          Youtube
        </v-btn>

      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
export default {
  name: "Contact",
  data () {
    return {
      //data
      contact: {
        name: '',
        email: [],
        phone: '',
        address: '',
      },
      socialNetworks: {
        facebook: {
          url: '',
          isEnabled: false
        },
        instagram: {
          url: '',
          isEnabled: false
        },
        youtube: {
          url: '',
          isEnabled: false
        },
      }
    }
  },
  methods: {
    async loadContactInfo() {
      try {

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/contactinfo`)

        if(response.data.data) {
          this.contact = { ...this.contact, ...response.data.data }
        }

      } catch (e) {
        console.error(e)
      }
    },
    async loadSocialNetworks() {
      try {

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/socialnetworks`)

        if(response.data.data) {
          this.socialNetworks = { ...this.socialNetworks, ...response.data.data }
        }

      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted() {
    Promise.all([this.loadContactInfo(), this.loadSocialNetworks()])
  }
}
</script>

<style lang="scss" scoped>
.roundedmap {
  border-radius: 5px;
}

.instagramButton {
  background: radial-gradient(circle farthest-corner at 35% 90%, #e2aa48, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.blurredHeader .v-image__image {
  filter: blur(5px);
}

</style>